<template>
  <div class="loginRecordPage">
    <Breadcrumb :title="$route.meta.title" />

    <div class="loginRecordPage__Content">
      <section class="filterWrap">
        <!-- 時間 -->
        <div class="itemWrap">
          <label>查詢日期：</label>
          <el-date-picker :editable="false" type="daterange" value-format="yyyy-MM-dd" range-separator="至" 
            start-placeholder="開始日期" 
            end-placeholder="結束日期"
            v-model="rangeDate"
          ></el-date-picker>
        </div>
        <!-- 名單類型 -->
        <div class="itemWrap">
          <label>查詢單位：</label>
          <el-select v-model="listQuery.DeptName" clearable placeholder="請選擇單位" no-data-text="目前無名單">
            <el-option v-for="item in deptNameSelectData" :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="itemWrap">
          <el-button @click="doSearch" type="danger" class="searchLog"><font-awesome-icon icon="search"  style="margin-right:5px;" />查詢</el-button>
        </div>
      </section>
      <div class="header">
        <div class="exportWrap">
          <download-csv :data="CSVData" name="系統登入紀錄表.csv">
            <el-button type="primary"><font-awesome-icon icon="download" style="margin-right:10px;" />匯出</el-button>
          </download-csv>
        </div>
      </div>
      <div class="content">
        <el-table ref="multipleTable" style="width: 100%" empty-text="暫無數據" border max-height="600" :data="tableData">
          <el-table-column label="單位" prop="DeptName" width="150">
            <template slot-scope="scope">
              <div>{{ scope.row.DeptName?scope.row.DeptName:"無" }}</div>
            </template>
          </el-table-column>
          <el-table-column label="使用者" prop="UserName" >
            <template slot-scope="scope">
              <div>{{ scope.row.UserName?scope.row.UserName:"無" }}</div>
            </template>
          </el-table-column>
          <el-table-column label="登入來源" prop="SourceIP" width="250"></el-table-column>
          <el-table-column label="登入時間" prop="CreateDt" width="250">
            <template slot-scope="scope">
              <div>{{ filterDate(scope.row.CreateDt) }}</div>
            </template>
          </el-table-column>
          <el-table-column  label="狀態" prop="StatusCode" min-width="15%">
            <template slot-scope="scope">
              <div>{{ transLogonStatus(scope.row.StatusCode) }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pageBox">
        <Pagination
          @currentChange="pageChange"
          :pageSize="this.listQuery.Page_Size"
          :propsCurrentPage="this.listQuery.Page_Num"
          :totalLists="totalLists">
        </Pagination>
      </div>

    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import moment from "moment";

export default {
  components: { Breadcrumb,Pagination },
  data() {
    return {
      deptNameSelectData: [],
      rangeDate: [moment().add(-1, "days").format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")],
      listQuery: {
        UniComNm: this.$store.state.userInfo.UniComNm,
        End_Date: moment().format("YYYY-MM-DD"),
        Start_Date: moment().add(-1, "days").format("YYYY-MM-DD"),
        DeptName: "",
        Page_Size: 10,
        Page_Num: 1,
      },
      tableData: [],
      totalLists: 0,
      CSVData: [],
    };
  },
  computed: {
    filterDate() {
      return (date) => {
        return this.$dayjs(date).format("YYYY-MM-DD HH:mm:ss");
      };
    },
    transLogonStatus(data){
      let text = "無";
      return (data) => {
        if(data==='200'){
          text = '成功'
        }else{
          text = '失敗'
        }
        return text 
      };
    },
  },
  methods: {
    doSearch(){
      this.$store.dispatch("loadingHandler", true);
      this.listQuery.Page_Num = 1
      this.deleteNullValue();
      // let temp = JSON.parse(JSON.stringify(this.listQuery))
      // temp.IsUser = true;
      console.log(this.listQuery);
      this.$api.apimgrs.loginRecord(this.listQuery).then((res) => {
        this.tableData = res.data;
        this.totalLists = res.total;
        this.$store.dispatch("loadingHandler", false);
      });
      this.getAllExportData();
    },
    pageChange(val) {
      this.listQuery.Page_Num = val;
      this.getList();
    },
    getCSVData() {
     
    },
    deleteNullValue() {
      if(!!this.rangeDate){
        if(this.rangeDate.length !== 0){
          this.listQuery.Start_Date = this.rangeDate[0]
          this.listQuery.End_Date = this.rangeDate[1]
        }
      }else{
        this.listQuery.Start_Date = "";
        this.listQuery.End_Date = "";
      }
      let keys = Object.keys(this.listQuery)
      keys.forEach((key)=>{
        if(Array.isArray(this.listQuery[key]) && this.listQuery[key].length===0){
          delete this.listQuery[key]
        }
        if(!this.listQuery[key]){
          delete this.listQuery[key]
        }
      })
    },
    getList() {
      this.$store.dispatch("loadingHandler", true);
      this.deleteNullValue();
      console.log(this.listQuery);
      this.$api.apimgrs.loginRecord(this.listQuery).then((res) => {        
        this.tableData = res.data;
        this.totalLists = res.total;
        console.log(this.tableData);
        this.$store.dispatch("loadingHandler", false);
      });
      this.$store.dispatch("loadingHandler", false);
    },
    getAllExportData(){
      let requestData = {};
      requestData = this.listQuery;
      requestData.Page_Num = 1;
      requestData.Page_Size = 9999;
      console.log(requestData);
      this.$api.apimgrs.loginRecord(requestData).then((res) => {
        let CSVData = [];
        res.data.forEach((item) => {
          const { DeptName, UserName, SourceIP,CreateDt,LogonStatus } = item;
          CSVData.push({ DeptName, UserName, SourceIP,CreateDt,LogonStatus });
        });
        this.CSVData = CSVData;
      });
      
    },
    getSelectOption() {
      this.$api.company
        .getUserList({UniComNm: this.$store.state.userInfo.UniComNm})
        .then((res) => {
          this.deptNameSelectData = res.department;
        });
    },
  },
  mounted() {
    this.getList();
    //this.getSelectOption(); //查詢單位的 api
    this.getAllExportData();
  },
};
</script>

<style lang="scss">
// * {
// outline: red solid 1px;
// }
.loginRecordPage {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  display: flex;
  padding:  5px 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;   

  .loginRecordPage__Content {
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .filterWrap{
      // padding: 20px 20px;
      display: flex;
      flex-direction: row;
      .itemWrap{
          margin: 5px 10px;
          // width: 500px;
          // width: 90%;
          display: flex;
          justify-content: center;
          align-items: center;
          label{
              white-space:nowrap;
          }
          &:nth-child(3){
            width:15%;
          }
      }

    }
    .header {
      display: flex;
      width: 90%;
      // outline: red solid 1px;
      justify-content: flex-end;
      margin: 0px 0px 10px 0px;
    } 

    .content, .pageBox {
      display: flex;
      width: 90%;
      flex-direction: row;
      justify-content: center;
      
    }
  }


}  


</style>
